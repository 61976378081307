import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import { Button, TextField } from "@mui/material";
import Select from "react-select";
import "./index.scss";
import SiTable from "../../core/table";
import { HeaderConfig } from "./config";
import { getOfflineData } from "../../utils/offline-services";
import { invokeApi, HTTP_METHODS } from "../../utils/http-service";
import { HOSTNAME, REST_URLS } from "../../utils/endpoints";
import ViewTicket from "./view-ticket";
import TicketCard from "./mobile-view-ticket";
import AppContext from "../../utils/context";
import { USER_ROLES } from "../../utils/constants";
import TicketFilters from "../../core/ticket-filters";
import { Add } from "@mui/icons-material";
const PastTickets = () => {
  const { userDetails = {} } = useContext(AppContext);
  const navigate = useNavigate();
  const [viewTicket, setViewTicket] = useState(null);

  const [pastTickets, setPastTickets] = useState({
    results: [],
  });
  const [filters, setFilters] = useState({
    page: 1,
    limit: 10,
    sortBy: "-createdAt",
  });

  useEffect(() => {
    if (!getOfflineData("user")) {
      navigate("/login");
    } else if (userDetails && userDetails.id) {
      loadData(filters);
    }
  }, [userDetails]);

  const loadData = (filters) => {
    let url = REST_URLS.LIST_OEM_TICKETS;

    if (userDetails.role === USER_ROLES.ADMIN) {
      url = REST_URLS.TICKETS;
    }
    invokeApi(HTTP_METHODS.GET, `${HOSTNAME}${url}`, null, filters).then(
      (response) => {
        if (response.results) {
          setPastTickets(response);
        }
      }
    );
  };

  const getComplaintDetails = (data) => {
    invokeApi(
      HTTP_METHODS.GET,
      `${HOSTNAME}${REST_URLS.COMPLAINT}/${data.complaintRecord}`,
      null
    ).then((response) => {
      if (response) {
        setViewTicket({
          ticketId: data.id,
          ...response,
        });
      }
    });
  };

  const onRowClick = (data) => {
    if (userDetails.role === USER_ROLES.ADMIN) {
      navigate(`/admin/details/${data.id}`);
    } else {
      getComplaintDetails(data);
    }
  };
  const applyFilters = (newFilter) => {
    let updatedFilters = { ...filters };
    if (newFilter.ticketId) {
      updatedFilters._id = newFilter.ticketId;
    } else {
      delete updatedFilters._id;
    }

    if (newFilter.serviceEngineer) {
      updatedFilters.serviceEngineer = newFilter.serviceEngineer;
    } else {
      delete updatedFilters.serviceEngineer;
    }

    loadData(updatedFilters);
    setFilters(updatedFilters);
  };
  return (
    <>
      <div className="past-tickets-container">
        <div className="header-tab">
          <span className="label">Tickets Overview</span>
          <div className="r-section">
            <TicketFilters applyFilters={applyFilters} />
            {userDetails.role !== USER_ROLES.ADMIN && (
              <Button
                variant="contained"
                color="primary"
                startIcon={<Add />}
                sx={{ borderRadius: 160 }}
                onClick={() => navigate("/oem/create")}
              >
                Create new Ticket
              </Button>
            )}
          </div>
        </div>

        <div className="si-hide-mobile">
          <SiTable
            header={HeaderConfig}
            data={pastTickets.results || []}
            currentPage={filters.page}
            displayPageDropDown={true}
            pageCount={pastTickets.totalPages}
            onClick={onRowClick}
            onChange={(event, page) => {
              setFilters({
                ...filters,
                page,
              });
              loadData({
                ...filters,
                page,
              });
            }}
          ></SiTable>
        </div>
        <div className="si-hide-web ">
          <div>
            <div className="ticket-container-mobile">
              <TextField
                variant="outlined"
                size="small"
                className="search-field"
              />
              <Select placeHolder="Filter by" />
            </div>
          </div>
          {pastTickets.results.map((ticket) => {
            return (
              <div key={ticket.id}>
                <div>
                  <TicketCard
                    onClick={() => onRowClick(ticket)}
                    ticketId={ticket.id}
                    status={ticket.status}
                    createdBy={ticket.createdBy}
                    assignedTo={ticket.serviceEngineer}
                    createdOn={ticket.createdAt}
                  ></TicketCard>
                </div>
              </div>
            );
          })}
        </div>

        <Drawer anchor="right" open={!!viewTicket}>
          <ViewTicket
            details={viewTicket || {}}
            onClose={() => setViewTicket(null)}
          />
        </Drawer>
      </div>
    </>
  );
};

export default PastTickets;
