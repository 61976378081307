import "./index.scss";

const AppLogo = ({ onClick }) => {
  return (
    <div className="app-logo-container" onClick={onClick}>
      <img
        src={require("../../resources/icons/logo.png")}
        className="app-logo"
      />      
    </div>
  );
};
export default AppLogo;
