/** @format */
export const HMR_RANGE_MAP = {
  zeroTo500: "0 to 500",
  fiveHunTo1000: "500 to 1000",
  thousandTo1500: "1000 to 1500",
  fifteenHundredTo2000: "1500 to 2000",
  above2000: "Above 2000",
};

const DEFAULT_SUMARY_DATA = {
  topIssueCausing: [
    {
      label: "Part",
      data: "-",
      count: 0,
    },
    {
      label: "List",
      data: "-",
      count: 0,
    },
    {
      label: "Tickets OEM",
      data: "-",
      count: 0,
    },
  ],
  topModelHMR: [
    {
      label: "Model",
      data: "-",
      count: 0,
    },
    {
      label: "HMR",
      data: "-",
      count: 0,
    },
    {
      label: "State",
      data: "-",
      count: 0,
    },
  ],
};

export const formatReports = (response) => {
  let reportsData = {
    ticketSummaryCards: [],
    maxStateIssues: {},
    minStateIssue: {},
    ...DEFAULT_SUMARY_DATA,
  };
  if (response.partWiseCounts && response.partWiseCounts.length > 0) {
    const sortedPartWiseData = response.partWiseCounts.sort(
      (a, b) => b.count - a.count
    );
    reportsData.topIssueCausing[0] = {
      label: "Part",
      data: sortedPartWiseData[0].part,
      count: sortedPartWiseData[0].count,
    };
  }
  if (response.listNoWiseCounts && response.listNoWiseCounts.length > 0) {
    const sortedListWiseData = response.listNoWiseCounts.sort(
      (a, b) => b.count - a.count
    );
    reportsData.topIssueCausing[1] = {
      label: "List ",
      data: sortedListWiseData[0].listNo,
      count: sortedListWiseData[0].count,
    };
  }
  if (response.oemWiseCounts && response.oemWiseCounts.length > 0) {
    const oemWiseCountsData = response.oemWiseCounts.sort(
      (a, b) => b.count - a.count
    );
    reportsData.topIssueCausing[2] = {
      label: "Tickets OEM",
      data: oemWiseCountsData[0].name,
      count: oemWiseCountsData[0].count,
    };
  }

  if (
    response.engineModalWiseCounts &&
    response.engineModalWiseCounts.length > 0
  ) {
    const engineModalWiseCountsData = response.engineModalWiseCounts.sort(
      (a, b) => b.count - a.count
    );
    reportsData.topModelHMR[0] = {
      label: "Model",
      data: engineModalWiseCountsData[0].engineModal,
      count: engineModalWiseCountsData[0].count,
    };
  }

  if (
    response.hmrWiseCounts &&
    Object.keys(response.hmrWiseCounts || {}).length > 0
  ) {
    let name = "";
    let count = 0;

    Object.keys(response.hmrWiseCounts || {}).forEach((key) => {
      if (response.hmrWiseCounts[key] > count) {
        name = key;
        count = response.hmrWiseCounts[key];
      }
    });

    reportsData.topModelHMR[1] = {
      label: "HMR",
      data: HMR_RANGE_MAP[name],
      count: count,
    };
  }

  if (response.stateWiseCounts && response.stateWiseCounts.length > 0) {
    reportsData.maxStateIssue = response.stateWiseCounts[0];
    reportsData.minStateIssue =
      response.stateWiseCounts[response.stateWiseCounts.length - 1];

    reportsData.topModelHMR[2] = {
      label: "State",
      data: response.stateWiseCounts[0].name,
      count: response.stateWiseCounts[0].count,
    };
  }

  reportsData.ticketSummaryCards = [
    {
      label: "Open",
      count: 10,
    },
    {
      label: "Without resolution",
      count: 12,
    },
    {
      label: "Reopened/Unresolved",
      count: 20,
    },
    {
      label: "Total tickets",
      count: 10,
    },
  ];

  return reportsData;
};

export const formatIndiaMapData = (list) => {
  let codes = {};
  list.forEach((item) => {
    codes[item.name] = {
      value: item.count,
      [item.name]: item.count,
    };
  });

  return codes;
};

export const ENG_DETAILS_STATE = {
  series: [
    {
      name: "Count in year",
      type: "column",
      data: [241, 204, 164, 111],
    },
    {
      name: "2020-21",
      type: "line",
      data: [
        null,
        null,
        null,
        null,
        8,
        20,
        21,
        27,
        17,
        20,
        13,
        23,
        32,
        13,
        19,
        28,
      ],
    },
    {
      name: "2021-22",
      type: "line",
      data: [
        null,
        null,
        null,
        null,
        22,
        9,
        14,
        27,
        11,
        13,
        15,
        21,
        29,
        10,
        11,
        22,
      ],
    },
    {
      name: "2022-23",
      type: "line",
      data: [
        null,
        null,
        null,
        null,
        23,
        20,
        17,
        14,
        12,
        9,
        15,
        11,
        12,
        11,
        10,
        10,
      ],
    },
    {
      name: "2023-24",
      type: "line",
      data: [null, null, null, null, 8, 14, 9, 11, 13, 6, 11, 17, 14, 8, 0, 0],
    },
  ],
  options: {
    colors: [
      function ({ value, seriesIndex, w }) {
        if (seriesIndex === 0) {
          switch (value) {
            case 241:
              return "#FF5151";
            case 204:
              return "#FFC002";
            case 164:
              return "#0270C0";
            case 111:
              return "#02B050";
            default:
              return "#000000";
          }
        } else {
          switch (seriesIndex) {
            case 1:
              return "#FF5151";
            case 2:
              return "#FFC002";
            case 3:
              return "#0270C0";
            case 4:
              return "#02B050";
            case 6:
              return "#000000";
          }
        }
      },
    ],
    chart: {
      type: "bar",
    },
    stroke: {
      width: [0, 4],
      curve: "smooth",
    },
    title: {
      text: "",
    },
    dataLabels: {
      enabled: true,
      enabledOnSeries: [0],
    },
    labels: [
      "2020-21",
      "2021-22",
      "2022-23",
      "2023-24",
      "APR",
      "MAY",
      "JUN",
      "JUL",
      "AUG",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
      "JAN",
      "FEB",
      "MAR",
    ],
    yaxis: [
      {
        title: {
          text: "Year Count",
        },
      },
      {
        seriesName: "2017-18",
        opposite: true,
        title: {
          text: "Month Count",
        },
      },
      {
        seriesName: "2017-18",
        show: false,
      },
      {
        seriesName: "2017-18",
        show: false,
      },
      {
        seriesName: "2017-18",
        show: false,
      },
      {
        seriesName: "2017-18",
        show: false,
      },
      {
        seriesName: "2017-18",
        show: false,
      },
    ],
    legend: {
      show: false,
    },
  },
};

export const CURRENT_ENG_DETAILS_STATE = {
  series: [
    {
      name: "2022-2023",
      type: "column",
      data: [],
    },
    {
      name: "Count In Each Month",
      type: "line",
      data: [
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
      ],
    },
  ],
  options: {
    colors: ["#000000"],
    chart: {
      height: 450,
      type: "line",
    },
    stroke: {
      width: [0, 4],
    },
    title: {
      text: "",
    },
    plotOptions: {
      bar: { columnWidth: "70%", distributed: true, barHeight: "85%" },
      line: { columnWidth: "70%", distributed: true, barHeight: "85%" },
    },
    dataLabels: {
      enabled: true,
      enabledOnSeries: [0],
    },
    labels: [
      "2022-2023",
      "APR",
      "MAY",
      "JUN",
      "JUL",
      "AUG",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
      "JAN",
      "FEB",
      "MAR",
    ],
    yaxis: [
      {
        title: {
          text: "Count",
        },
      },
      {
        opposite: true,
        title: {
          text: "Counts",
        },
      },
    ],
    legend: {
      show: false,
    },
  },
};

export const CUR_MONTH_INDX = {
  APR: 1,
  MAY: 2,
  JUN: 3,
  JUL: 4,
  AUG: 5,
  SEP: 6,
  OCT: 7,
  NOV: 8,
  DEC: 9,
  JAN: 10,
  FEB: 11,
  MAR: 12,
};

export const formatAllInfoResponse = (allInfo) => {
  if (
    allInfo &&
    allInfo.engineModalWiseCounts &&
    allInfo.engineModalWiseCounts.length > 0
  ) {
    allInfo.engineModalWiseCounts = allInfo.engineModalWiseCounts.sort((a, b) =>
      (a.engineModal || "").localeCompare(b.engineModal, "en", {
        numeric: true,
      })
    );
  }

  return allInfo;
};
