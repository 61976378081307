import {
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import "./index.scss";
import Email from "../../resources/icons/email.svg";
import Lock from "../../resources/icons/lock.svg";
import {
  EmailOutlined,
  RemoveRedEye,
  VisibilityOff,
} from "@mui/icons-material";
import { useState } from "react";

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#A0AAB4",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#B2BAC2",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#E0E3E7",
    },
    "&:hover fieldset": {
      borderColor: "#B2BAC2",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#6F7E8C",
      color: "#fff",
    },
  },
});

const LoginCard = ({
  title,
  loginDetails,
  onInputChange,
  onLogin,
  setRememberMe,
  rememberMe,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <div className="login-card-container">
      <div className="card-title">{title}</div>
      <div className="login-form">
        <div>
          <Typography sx={{ paddingBottom: "5px" }}>Email</Typography>
          <CssTextField
            size="small"
            fullWidth
            name="email"
            value={loginDetails.email}
            onChange={onInputChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img src={Email} sx={{ color: "#fff" }} />
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div>
          <Typography sx={{ color: "#000", paddingBottom: "5px" }}>
            Password
          </Typography>
          <CssTextField
            size="small"
            name="password"
            value={loginDetails.password}
            type={showPassword ? "text" : "password"}
            onChange={onInputChange}
            fullWidth
            InputProps={{             
              startAdornment: (
                <InputAdornment position="start">
                  
                  <img src={Lock} sx={{ color: "#000" }} />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="start">
                  <IconButton onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? (
                      <VisibilityOff sx={{ color: "#000" }} />
                    ) : (
                      <RemoveRedEye sx={{ color: "#000" }} />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>

        <FormControlLabel
          sx={{ color: "#000", paddingBottom: "5px" }}
          control={
            <Checkbox
              checked={rememberMe}
              onChange={() => setRememberMe(!rememberMe)}
            />
          }
          label="Remember me"
        />
        <Button variant="contained" size="large" onClick={onLogin}>
          Log In
        </Button>
        {/* <Typography sx={{ color: "#fff" }}>
          Forgot password? <a>Click here</a>
        </Typography> */}
      </div>
    </div>
  );
};
export default LoginCard;
