/** @format */

import React from "react";
import ReactApexChart from "react-apexcharts";
import { ENG_DETAILS_STATE } from "../helper";
import { Typography } from "@mui/material";

const PastEngineReplacementTrend = () => {
  return (
    <div className="past-engine-replacement-tend">
      <Typography className="title">ENGINE REPLACEMENT TREND</Typography>
      <div className="chart-container">
        <ReactApexChart
          options={ENG_DETAILS_STATE.options}
          series={ENG_DETAILS_STATE.series}
          height={350}
        />
        <div className="eng-rep-summary-table">
          <div className="st-row st-header">
            <div></div>
            <div>2020-21</div>
            <div>2021-22</div>
            <div>2022-23</div>
            <div>2023-24</div>
            <div>APR</div>
            <div>MAY</div>
            <div>JUN</div>
            <div>JUL</div>
            <div>AUG</div>
            <div>SEP</div>
            <div>OCT</div>
            <div>NOV</div>
            <div>DEC</div>
            <div>JAN</div>
            <div>FEB</div>
            <div>MAR</div>
          </div>
          <div className="st-row">
            <div>YOY</div>
            <div>241</div>
            <div>204</div>
            <div>164</div>
            <div>111</div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div className="st-row">
            <div>TARGET</div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            {[8, 8, 8, 6, 6, 6, 4, 4, 4, 2, 2, 2].map((value,index) => (
              <div key={value + index}>{value}</div>
            ))}
          </div>
          <div className="st-row">
            <div>2020-21</div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            {[8, 20, 21, 27, 17, 20, 13, 23, 32, 13, 19, 28].map((value,index) => (
              <div key={value + index}>{value}</div>
            ))}
          </div>
          <div className="st-row">
            <div>2021-22</div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            {[22, 9, 14, 27, 11, 13, 15, 21, 29, 10, 11, 22].map(
              (value, index) => (
                <div key={value + index}>{value}</div>
              )
            )}
          </div>
          <div className="st-row">
            <div>2022-23</div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            {[23, 20, 17, 14, 12, 9, 15, 11, 12, 11, 10, 10].map((value,index) => (
              <div key={value + index}>{value}</div>
            ))}
          </div>
          <div className="st-row">
            <div>2023-24</div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            {[8, 14, 9, 11, 13, 6, 11, 17, 14, 8, 0, 0].map((value,index) => (
              <div key={value + index}>{value}</div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PastEngineReplacementTrend;
