import { Typography } from "@mui/material";
import "./index.scss";
import { useNavigate } from "react-router-dom";
import { USER_ROLES } from "../../utils/constants";

const FooterLinks = ({ userType }) => {
  const navigate = useNavigate();
  return (
    <div className="footer-links-container">
      {/* <Typography className="nav-link" component="a">
        Parts Catalogue
      </Typography> */}
      <Typography
        className="nav-link"
        component="a"
        href="http://www.simpsons.in/"
        target="_blank"
      >        
        Our Website
      </Typography>
      {/* {userType === USER_ROLES.ADMIN && (
        <Typography
          className="nav-link"
          component="span"
          onClick={() => {
            navigate(
              USER_ROLES.ADMIN === userType
                ? `/login?type=${USER_ROLES.OEM}`
                : `/login?type=${USER_ROLES.ADMIN}`
            );
          }}
        >
          {USER_ROLES.ADMIN === userType ? "OEM Login" : "Admin Login"}
        </Typography>
      )} */}
    </div>
  );
};
export default FooterLinks;
