export const ACTIVE_BUTTON_CONFIG = {
  Dashboard: [
    // {
    //   label: "Engine Replacement",
    //   redirectTo: "/admin/overview?index=0",
    //   info: "Involves the removal of a vehicle's existing engine and installing a new one.",
    // },
    {
      label: "Trend Overview",
      redirectTo: "/admin/overview?index=8",
      info: "How engine replacement trend is happing over the year",
    },
  ],
  "Complaint Overview": [
    {
      label: "OEM Wise",
      redirectTo: "/admin/overview?index=2",
      info: "Know OEM wise details in charts",
    },
    {
      label: "Region Wise",
      redirectTo: "/admin/overview?index=3",
      info: "Know Region wise details in charts",
    },
    {
      label: "Engine Model Wise",
      redirectTo: "/admin/overview?index=4",
      info: "Know Engine Model wise details in charts",
    },
    {
      label: "Hour Wise",
      redirectTo: "/admin/overview?index=5",
      info: "Know Hour Wise details in charts",
    },
    {
      label: "Part Wise Trend",
      redirectTo: "/admin/overview?index=7",
      info: "Know Part Wise Trend in charts",
    },
  ],
  "Complaint Handling": [
    {
      label: "Tickets",
      redirectTo: "/admin/tickets",
      info: "you're experiencing performance issues, unusual noises, or it's time for an upgrade, our expert team is here to assist you.",
    },
    {
      label: "Reports",
      redirectTo: "/admin/reports",
      info: "where you can access comprehensive insights into the status and performance of recent service requests.",
    },
    {
      label: "Admin Settings",
      redirectTo: "/admin/setup",
      info: "users can be created, and other configurations can be managed, it's crucial to provide a user-friendly and organized interface.",
    },
  ],
};
