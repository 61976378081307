/** @format */

import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, IconButton, TextField } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import './index.scss';
import { invokeApi, HTTP_METHODS } from '../../utils/http-service';
import { HOSTNAME, REST_URLS } from '../../utils/endpoints';
import { toast } from 'react-toastify';
import {
  getFormConfig,
  isFormValid,
  formatFreeServicesPayload,
  convertFreeServiceObjectToArray,
} from './helper';
import FreeServices from './free-services';
import PastHistory from './past-history';
import Select from 'react-select';
import { getOfflineData } from '../../utils/offline-services';
import SiFileUpload from '../../core/file-uploader';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { splitFileName } from '../../utils';
import { Add } from '@mui/icons-material';

const sx = {
  input: {
    background: '#fff',
    borderColor: 'gray',
  },
};

const CreateTicket = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [errors, setErrors] = useState({});
  const [addFreeServices, setAddFreeServices] = useState(false);
  const [addHistory, setAddHistory] = useState(false);
  const [servicesList, setServicesList] = useState([]);
  const [oemsList, setOemsList] = useState([]);
  const [files, setFiles] = useState([]);
  const [complaintCodes, setComplaintCodes] = useState([]);
  const [pastHistory, setPastHistory] = useState([]);
  const [ticketDetails, setTicketDetails] = useState({
    attachments: 'no',
    actionTakenByOem: '',
  });

  useEffect(() => {
    invokeApi(HTTP_METHODS.GET, `${HOSTNAME}${REST_URLS.LIST_OEMS}`, null).then(
      (response) => {
        if (Array.isArray(response) && response) {
          let user = getOfflineData('user') || {};
          let oems = response.map((r) => {
            return {
              ...r,
              label: r.name,
              value: r.id,
            };
          });
          setTicketDetails({
            ...ticketDetails,
            oemName:
              (oems || []).find((oem) => oem.id === user.oemNameId) || {},
          });
          setOemsList(oems);
          if (id) {
            loadData(oems);
          }
        }
      }
    );
    invokeApi(
      HTTP_METHODS.GET,
      `${HOSTNAME}${REST_URLS.LIST_COMPLAINT_CODES}`,
      null
    ).then((response) => {
      if (Array.isArray(response.results) && response.results) {
        let codes = response.results.map((r) => {
          return {
            ...r,
            label: `${r.code} - ${r.description || ''}`,
            value: r.code,
          };
        });
        setComplaintCodes(codes);
      }
    });
  }, []);

  const loadData = (oems) => {
    invokeApi(
      HTTP_METHODS.GET,
      `${HOSTNAME}${REST_URLS.COMPLAINT}/${id}`,
      null
    ).then((response) => {
      if (response) {
        response.oemName = (oems || []).find(
          (o) => o.name === response.oemName
        );
        setTicketDetails(response);
      }
      if (response.pastHistory) {
        setPastHistory(response.pastHistory || []);
      }
      if (response.freeServiceDetails) {
        setServicesList(
          convertFreeServiceObjectToArray(response.freeServiceDetails || [])
        );
      }
    });
  };

  const onInputChange = (event) => {
    setTicketDetails({
      ...ticketDetails,
      [event.target.name]: event.target.value,
    });
  };

  const onSubFormChange = (event, dataLabel) => {
    setTicketDetails({
      ...ticketDetails,
      [dataLabel]: {
        ...ticketDetails[dataLabel],
        [event.target.name]: event.target.value,
      },
    });
  };

  const createTicket = () => {
    let errors = isFormValid(ticketDetails, servicesList);

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }

    let payload = JSON.parse(JSON.stringify(ticketDetails));
    payload.freeServiceDetails = formatFreeServicesPayload(servicesList);
    payload.pastHistory = pastHistory;
    payload.oemName = ticketDetails.oemName.label;
    const { natureOfCustomerComplaint = {} } = ticketDetails;
    payload.natureOfCustomerComplaint = natureOfCustomerComplaint.value;

    if (files.length > 0) {
      payload.attachmentUrls = files.map((f) => splitFileName(f.url || ''));
    }

    invokeApi(
      HTTP_METHODS.POST,
      `${HOSTNAME}${REST_URLS.COMPLAINT}`,
      payload
    ).then((response) => {
      if (response.message) {
        toast.error('Failed to create ticket');
      } else {
        navigate('/');
      }
    });
  };

  const updateTicket = () => {
    let errors = isFormValid(ticketDetails, servicesList);
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }

    let payload = JSON.parse(JSON.stringify(ticketDetails));
    payload.freeServiceDetails = formatFreeServicesPayload(servicesList);
    payload.pastHistory = pastHistory;
    payload.oemName = ticketDetails.oemName.label;

    const { natureOfCustomerComplaint = {} } = ticketDetails;
    payload.natureOfCustomerComplaint = natureOfCustomerComplaint.value;
    delete payload.active;
    delete payload.archive;
    delete payload.createdAt;
    delete payload.updatedAt;
    delete payload.oem;
    delete payload.id;

    if (files.length > 0) {
      payload.attachmentUrls = files.map((f) => splitFileName(f.url || ''));
    }

    invokeApi(
      HTTP_METHODS.PUT,
      `${HOSTNAME}${REST_URLS.COMPLAINT}/${id}`,
      payload
    ).then((response) => {
      if (response.message) {
        toast.error('Failed to create ticket');
      } else {
        navigate('/');
      }
    });
  };

  const onServiceListDelete = (index) => {
    servicesList.splice(index, 1);
    setServicesList([...servicesList]);
  };

  const onPastHistoryDelete = (index) => {
    pastHistory.splice(index, 1);
    setPastHistory([...pastHistory]);
  };

  const inputElements = (config) => {
    if (config.type === 'FREE_SERVICES') {
      return (
        <div className='form-row' key={config.label}>
          <span className='label'>{config.label}</span>
          <div className='sub-form'>
            <Button
              variant='text'
              color='primary'
              onClick={() => setAddFreeServices(true)}
            >
              Add free services
            </Button>
            {config.error && (
              <div className='error'>Required free services</div>
            )}
            <div>
              {servicesList && servicesList.length > 0 && (
                <div className='service-row'>
                  <div>Service</div>
                  <div>Date</div>
                  <div>Hours</div>
                  <div></div>
                </div>
              )}
              {servicesList &&
                servicesList.map((service, index) => {
                  return (
                    <div className='service-row'>
                      <span>{(service.name && service.name.label) || ''}</span>
                      <span>{service.date}</span>
                      <span>{service.runningHours}</span>
                      <IconButton
                        size='small'
                        onClick={() => onServiceListDelete(index)}
                      >
                        <CancelIcon fontSize='small' />
                      </IconButton>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      );
    }

    if (config.type === 'PAST_HISTORY') {
      return (
        <div className='form-row' key={config.label}>
          <span className='label'>{config.label}</span>
          <div className='sub-form'>
            <Button
              variant='text'
              color='primary'
              onClick={() => setAddHistory(true)}
            >
              Add past history
            </Button>
            <div>
              {pastHistory.length > 0 && (
                <div className='service-row'>
                  <div>Date Of Failure</div>
                  <div>Complaint Reported</div>
                  <div>Hours</div>
                  <div></div>
                </div>
              )}
              {pastHistory.map((h, index) => {
                return (
                  <div className='service-row'>
                    <span>{h.dateOfFailure}</span>
                    <span>{h.complaintReported}</span>
                    <span>{h.hours}</span>
                    <IconButton
                      size='small'
                      onClick={() => onPastHistoryDelete(index)}
                    >
                      <CancelIcon fontSize='small' />
                    </IconButton>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      );
    }

    if (config.subForm) {
      return (
        <div className='form-row' key={config.label}>
          <span className='label'>{config.label}</span>
          <div className='sub-form'>
            {config.subForm.map((subConfig) => {
              return (
                <TextField
                  key={subConfig.label}
                  placeholder={subConfig.label}
                  size='small'
                  value={subConfig.value}
                  name={subConfig.name}
                  type={subConfig.type}
                  sx={sx}
                  onChange={(event) => onSubFormChange(event, config.name)}
                  error={config.error}
                  helperText={config.error ? 'Required' : ''}
                />
              );
            })}
          </div>
        </div>
      );
    }

    return (
      <div className='form-row' key={config.label}>
        <span className='label'>{config.label}</span>
        {config.type === 'select' ? (
          <div>
            <Select
              placeHolder='Name of OEM'
              options={config.options}
              value={config.value}
              onChange={(value) => {
                onInputChange({
                  target: {
                    name: config.name,
                    value: value,
                  },
                });
              }}
              isDisabled={config.disabled}
            />
            {config.error ? <div className='error'> Required</div> : null}
          </div>
        ) : (
          <TextField
            size='small'
            value={config.value}
            name={config.name}
            type={config.type}
            sx={sx}
            onChange={onInputChange}
            error={config.error}
            helperText={config.error ? 'Required' : ''}
          />
        )}
      </div>
    );
  };

  const saveServices = (newService) => {
    setServicesList([...servicesList, newService]);
    setErrors({
      ...errors,
      freeServicesErr: false,
    });
    setAddFreeServices(false);
  };

  const saveHistory = (newHistory) => {
    setPastHistory([...pastHistory, newHistory]);
    setAddHistory(false);
  };

  const callBack = (newFile) => {
    setFiles([...files, newFile]);
  };

  const onDelete = (index) => {
    files.splice(index, 1);
    setFiles([...files]);
  };

  return (
    <div className='create-tickets'>
      <div className='back-container'>
        <IconButton onClick={() => navigate('/')}>
          <ArrowBackIosIcon />
        </IconButton>
        <span className='s-heading'>{id ? 'Update ticket' : 'New Ticket'}</span>
      </div>
      <div className='create-ticket-form'>
        {getFormConfig(ticketDetails, oemsList, complaintCodes, errors).map(
          (config) => {
            return inputElements(config);
          }
        )}

        <SiFileUpload id={null} callBack={callBack} />

        {files.map((r, index) => {
          return (
            <div key={r.blobName + index}>
              <span>{r.blobName}</span>
              <IconButton onClick={() => onDelete(index)}>
                <HighlightOffIcon />
              </IconButton>
            </div>
          );
        })}

        <div className='buttons'>
          <Button
            variant='contained'
            color="inherit"
            sx={{borderRadius:160}}
            onClick={() => navigate('/oem')}
          >
            Go Back
          </Button>
          <Button
            variant='contained'
            color='primary'
            startIcon={<Add/>}
            sx={{borderRadius:160}}
            onClick={id ? updateTicket : createTicket}
          >
            {id ? 'Update ticket' : 'Create Ticket'}
          </Button>
        </div>
      </div>
      {addFreeServices && (
        <FreeServices
          handleClose={() => setAddFreeServices(false)}
          onSave={saveServices}
        />
      )}
      {addHistory && (
        <PastHistory
          handleClose={() => setAddHistory(false)}
          onSave={saveHistory}
        />
      )}
    </div>
  );
};
export default CreateTicket;
