import React from "react";
import DownloadIcon from "@mui/icons-material/Download";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

import "./index.scss";
import { IconButton } from "@mui/material";
import { exportToExcel } from "../../utils";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip , Legend);

const options = {
  legend: {
    display: false,
  },
  responsive: true,
};

const CustomBarChart = ({ title, data = [], xDataKey, bDataKey }) => {
  const width = window.innerWidth > 420 ? 500 : 300;
  const labels = data.map((d) => d[xDataKey]);
  const state = {
    labels,
    datasets: [
      {
        label: "Count",
        data: data.map((d) => d[bDataKey]),
        backgroundColor: "#005CE8",
      },
    ],
  };

  return (
    <div className="bar-chart-container">
      <div className="title">
        {title}
        <IconButton
          disabled={data.length === 0}
          onClick={() => exportToExcel(data, xDataKey)}
        >
          <DownloadIcon />
        </IconButton>
      </div>
      {data.length > 0 ? (
        <Bar options={options} data={state} />
      ) : (
        <div
          style={{
            width: `${width}px`,
          }}
        >
          No data to display
        </div>
      )}
    </div>
  );
};

export default CustomBarChart;
