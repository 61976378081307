import * as React from "react";
import Popper from "@mui/material/Popper";
import AsyncSelect from "react-select/async";
import Fade from "@mui/material/Fade";
import Paper from "@mui/material/Paper";
import {
  Button,
  IconButton,
  TextField,
  Typography,
  debounce,
} from "@mui/material";
import { FilterAlt, FilterAltOffOutlined } from "@mui/icons-material";
import "./index.scss";
import { HTTP_METHODS, invokeApi } from "../../utils/http-service";
import { HOSTNAME, REST_URLS } from "../../utils/endpoints";
import Select from "react-select";

export default function TicketFilters({ applyFilters }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [selectedOption, setSelectedOption] = React.useState(null);
  const [selectedStatus, setSelectedStatus] = React.useState(null);
  const [selectedServiceEngineer, setServiceEngineer] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(!open);
  };

  const onApplyFilters = () => {
    let filters = {};
    if (selectedOption) {
      filters.ticketId = selectedOption.value;
    }

    if (selectedStatus) {
      filters.status = selectedStatus.value;
    }

    if (selectedServiceEngineer) {
      filters.serviceEngineer = selectedServiceEngineer.value;
    }

    applyFilters(filters);
    setOpen(!open);
  };

  const onClearFilters = () => {
    applyFilters({});
    setSelectedOption(null);
    setSelectedStatus(null);
    setServiceEngineer(null);
    setOpen(false);
  };

  const loadOptions = async (inputValue, callback) => {
    try {
      // Replace 'your-api-endpoint' with the actual endpoint you want to use
      const response = await invokeApi(
        HTTP_METHODS.GET,
        `${HOSTNAME}${REST_URLS.SEARCH_TICKET_ID}`,
        null,
        { _id: inputValue }
      );
      const { data = [] } = response || {};

      const options = data.map((item) => ({
        label: item._id,
        value: item._id,
      }));

      callback(options);
    } catch (error) {
      console.error("Error fetching options:", error);
      callback([]);
    }
  };

  const debouncedLoadOptions = debounce(loadOptions, 300);

  const loadServiceEngineerOptions = async (inputValue, callback) => {
    try {
      // Replace 'your-api-endpoint' with the actual endpoint you want to use
      const response = await invokeApi(
        HTTP_METHODS.GET,
        `${HOSTNAME}${REST_URLS.SEARCH_SERVICE_ENGINEER}`,
        null,
        { serviceEngineer: inputValue }
      );

      const options = response.map((item) => ({
        label: item.name,
        value: item.id,
      }));

      callback(options);
    } catch (error) {
      console.error("Error fetching options:", error);
      callback([]);
    }
  };

  const debounceLoadServiceEngineerOptions = debounce(
    loadServiceEngineerOptions,
    300
  );

  return (
    <div className="ticket-filter-container">
      <Popper
        sx={{ zIndex: 1200 }}
        open={open}
        anchorEl={anchorEl}
        placement="left-start"
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper sx={{ padding: "10px" }} className="tf-paper">
              <div>
                <div className="ticket-filter-form">
                  <div>
                    <Typography sx={{ pb: 1 }}>Ticket Id</Typography>
                    <AsyncSelect
                      isClearable
                      value={selectedOption}
                      onChange={setSelectedOption}
                      loadOptions={debouncedLoadOptions}
                      placeholder="Search ticket id"
                    />
                  </div>
                  <div>
                    <Typography sx={{ pb: 1 }}>Service Engineer</Typography>
                    <AsyncSelect
                      isClearable
                      value={selectedServiceEngineer}
                      onChange={setServiceEngineer}
                      loadOptions={debounceLoadServiceEngineerOptions}
                      placeholder="Search Service Engineer"
                    />
                  </div>
                  {/* <div>
                    <Typography sx={{ pb: 1 }}>Ticket Status</Typography>
                    <Select
                      isClearable
                      value={selectedStatus}
                      onChange={setSelectedStatus}
                      options={[]}
                      placeholder="Search Status"
                    />
                  </div> */}
                </div>
                <div className="ticket-filter-buttons">
                  <Button variant="outlined" onClick={() => setOpen(!open)}>
                    Cancel
                  </Button>
                  <Button variant="contained" onClick={onApplyFilters}>
                    Apply
                  </Button>
                </div>
              </div>
            </Paper>
          </Fade>
        )}
      </Popper>
      {(selectedServiceEngineer || selectedOption) && (
        <IconButton onClick={onClearFilters} title="Clear Filters">
          <FilterAltOffOutlined sx={{ color: "#E84646" }} />
        </IconButton>
      )}
      <IconButton onClick={handleClick}>
        <FilterAlt color="primary" />
      </IconButton>
    </div>
  );
}
