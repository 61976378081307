import SvgIcon from "@mui/material/SvgIcon";

export const DashboardIcon1 = (props) => {
  const { active } = props;
  if (active) {
    return (
      <SvgIcon>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            d="M16.875 16.875V9.02642C16.875 8.93934 16.8568 8.85322 16.8216 8.77358C16.7863 8.69395 16.7349 8.62255 16.6704 8.56397L10.42 2.88154C10.3049 2.77694 10.155 2.71899 9.99955 2.71899C9.84406 2.719 9.69415 2.77696 9.57911 2.88157L3.32954 8.56397C3.26511 8.62255 3.21364 8.69395 3.17842 8.77358C3.14319 8.85321 3.125 8.93932 3.125 9.0264V16.875"
            stroke="#307AEC"
            stroke-width="1.25"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M1.25 16.875H18.75"
            stroke="#307AEC"
            stroke-width="1.25"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M11.875 16.8743V12.4993C11.875 12.3336 11.8092 12.1746 11.6919 12.0574C11.5747 11.9402 11.4158 11.8743 11.25 11.8743H8.75C8.58424 11.8743 8.42527 11.9402 8.30806 12.0574C8.19085 12.1746 8.125 12.3336 8.125 12.4993V16.8743"
            stroke="#307AEC"
            stroke-width="1.25"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </SvgIcon>
    );
  }
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          opacity="0.2"
          d="M16.248 9.02642V16.2499M16.248 9.02642L16.2481 9.02641L16.248 16.2499M16.248 9.02642L9.99762 3.34399L3.74805 9.0264V16.2499M16.248 9.02642L3.74805 16.2499M16.248 16.2499L12.4976 16.2494V11.8743V11.2493H11.8726H8.12259H7.49759V11.8743V16.2494L3.74805 16.2499M16.248 16.2499H3.74805"
          fill="#767F82"
          stroke="#767F82"
          stroke-width="1.25"
        />
        <path
          d="M16.873 16.875V9.02642C16.873 8.93934 16.8548 8.85322 16.8196 8.77358C16.7844 8.69395 16.7329 8.62255 16.6685 8.56397L10.418 2.88154C10.303 2.77694 10.1531 2.71899 9.9976 2.71899C9.84211 2.719 9.6922 2.77696 9.57716 2.88157L3.32759 8.56397C3.26316 8.62255 3.21169 8.69395 3.17646 8.77358C3.14124 8.85321 3.12305 8.93932 3.12305 9.0264V16.875"
          stroke="#767F82"
          stroke-width="1.25"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M1.24805 16.875H18.748"
          stroke="#767F82"
          stroke-width="1.25"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M11.8721 16.8743V12.4993C11.8721 12.3336 11.8062 12.1746 11.689 12.0574C11.5718 11.9402 11.4128 11.8743 11.2471 11.8743H8.74707C8.58131 11.8743 8.42234 11.9402 8.30513 12.0574C8.18792 12.1746 8.12207 12.3336 8.12207 12.4993V16.8743"
          stroke="#767F82"
          stroke-width="1.25"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};

export const ReportsIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M15 9H9.6C9.44087 9 9.28826 9.06321 9.17574 9.17574C9.06321 9.28826 9 9.44087 9 9.6V16M9 21H15H9ZM9 21V16V21ZM9 21H3.6C3.44087 21 3.28826 20.9368 3.17574 20.8243C3.06321 20.7117 3 20.5591 3 20.4V16.6C3 16.4409 3.06321 16.2883 3.17574 16.1757C3.28826 16.0632 3.44087 16 3.6 16H9V21ZM15 21V9V21ZM15 21H20.4C20.5591 21 20.7117 20.9368 20.8243 20.8243C20.9368 20.7117 21 20.5591 21 20.4V3.6C21 3.44087 20.9368 3.28826 20.8243 3.17574C20.7117 3.06321 20.5591 3 20.4 3H15.6C15.4409 3 15.2883 3.06321 15.1757 3.17574C15.0632 3.28826 15 3.44087 15 3.6V9V21Z"
        stroke="white"
        strokeWidth="1.5"
      />
    </SvgIcon>
  );
};

export const SetupIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M11 6V10.59H8.70995C8.25995 10.59 8.03995 11.13 8.35995 11.44L11.65 14.73C11.85 14.93 12.16 14.93 12.36 14.73L15.65 11.44C15.7185 11.3701 15.765 11.2817 15.7837 11.1856C15.8024 11.0895 15.7925 10.9901 15.7553 10.8996C15.718 10.8091 15.655 10.7315 15.5741 10.6765C15.4931 10.6214 15.3978 10.5914 15.3 10.59H13V6C13 5.45 12.55 5 12 5C11.45 5 11 5.45 11 6ZM7.09995 14C6.48995 14 5.98995 14.55 6.10995 15.15C6.64995 17.91 9.07995 20 12 20C14.92 20 17.35 17.91 17.89 15.15C18.01 14.55 17.51 14 16.9 14C16.41 14 16.02 14.35 15.92 14.83C15.53 16.64 13.93 18 12 18C10.07 18 8.46995 16.64 8.08995 14.83C7.98995 14.35 7.58995 14 7.09995 14Z"
        fill="white"
      />
    </SvgIcon>
  );
};

export const ComplaintHandlingIcon = (props) => {
  if (props.active) {
    return (
      <SvgIcon>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            d="M2.5 16.25V5C2.5 4.83424 2.56585 4.67527 2.68306 4.55806C2.80027 4.44085 2.95924 4.375 3.125 4.375H7.29167C7.4269 4.375 7.55848 4.41886 7.66667 4.5L9.83333 6.125C9.94152 6.20614 10.0731 6.25 10.2083 6.25H15.625C15.7908 6.25 15.9497 6.31585 16.0669 6.43306C16.1842 6.55027 16.25 6.70924 16.25 6.875V8.75"
            stroke="#005CE8"
            stroke-width="1.25"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M2.5 16.25L4.84285 10.3929C4.88924 10.2769 4.96932 10.1775 5.07275 10.1075C5.17619 10.0374 5.29823 10 5.42315 10H9.18576C9.30916 10 9.42979 9.96348 9.53245 9.89503L11.0925 8.85497C11.1952 8.78652 11.3158 8.75 11.4392 8.75H17.8829C17.9819 8.75 18.0795 8.77353 18.1677 8.81866C18.2558 8.86379 18.332 8.92922 18.3899 9.00956C18.4478 9.0899 18.4858 9.18284 18.5007 9.28074C18.5156 9.37864 18.5071 9.47869 18.4758 9.57264L16.25 16.25H2.5Z"
            stroke="#005CE8"
            stroke-width="1.25"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </SvgIcon>
    );
  }
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M2.5 16.25V5C2.5 4.83424 2.56585 4.67527 2.68306 4.55806C2.80027 4.44085 2.95924 4.375 3.125 4.375H7.29167C7.4269 4.375 7.55848 4.41886 7.66667 4.5L9.83333 6.125C9.94152 6.20614 10.0731 6.25 10.2083 6.25H15.625C15.7908 6.25 15.9497 6.31585 16.0669 6.43306C16.1842 6.55027 16.25 6.70924 16.25 6.875V8.75"
          stroke="#626C70"
          stroke-width="1.25"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M2.5 16.25L4.84285 10.3929C4.88924 10.2769 4.96932 10.1775 5.07275 10.1075C5.17619 10.0374 5.29823 10 5.42315 10H9.18576C9.30916 10 9.42979 9.96348 9.53245 9.89503L11.0925 8.85497C11.1952 8.78652 11.3158 8.75 11.4392 8.75H17.8829C17.9819 8.75 18.0795 8.77353 18.1677 8.81866C18.2558 8.86379 18.332 8.92922 18.3899 9.00956C18.4478 9.0899 18.4858 9.18284 18.5007 9.28074C18.5156 9.37864 18.5071 9.47869 18.4758 9.57264L16.25 16.25H2.5Z"
          stroke="#626C70"
          stroke-width="1.25"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};

export const TicketsIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M6 7H14V9H6V7Z" fill="white" />
      <path d="M6 11H18V13H6V11Z" fill="white" />
      <path d="M6 15H8.99V17H6V15Z" fill="white" />
      <path
        d="M14 3L11 0V2H4C3.46957 2 2.96086 2.21071 2.58579 2.58579C2.21071 2.96086 2 3.46957 2 4V20C2 20.5304 2.21071 21.0391 2.58579 21.4142C2.96086 21.7893 3.46957 22 4 22H8V20H4V4H11V6L14 3Z"
        fill="white"
      />
      <path
        d="M10 21L13 24V22H20C20.5304 22 21.0391 21.7893 21.4142 21.4142C21.7893 21.0391 22 20.5304 22 20V4C22 3.46957 21.7893 2.96086 21.4142 2.58579C21.0391 2.21071 20.5304 2 20 2H16V4H20V20H13V18L10 21Z"
        fill="white"
      />
    </SvgIcon>
  );
};

export const TimeIcon = (props) => {
  if (props?.active) {
    return (
      <SvgIcon>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            d="M16.25 3.125H3.75C3.40482 3.125 3.125 3.40482 3.125 3.75V16.25C3.125 16.5952 3.40482 16.875 3.75 16.875H16.25C16.5952 16.875 16.875 16.5952 16.875 16.25V3.75C16.875 3.40482 16.5952 3.125 16.25 3.125Z"
            stroke="#0E5FD9"
            stroke-width="1.25"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M13.75 1.875V4.375"
            stroke="#0E5FD9"
            stroke-width="1.25"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M6.25 1.875V4.375"
            stroke="#0E5FD9"
            stroke-width="1.25"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M3.125 6.875H16.875"
            stroke="#0E5FD9"
            stroke-width="1.25"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </SvgIcon>
    );
  }

  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M16.25 3.125H3.75C3.40482 3.125 3.125 3.40482 3.125 3.75V16.25C3.125 16.5952 3.40482 16.875 3.75 16.875H16.25C16.5952 16.875 16.875 16.5952 16.875 16.25V3.75C16.875 3.40482 16.5952 3.125 16.25 3.125Z"
          stroke="#626C70"
          stroke-width="1.25"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M13.75 1.875V4.375"
          stroke="#626C70"
          stroke-width="1.25"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M6.25 1.875V4.375"
          stroke="#626C70"
          stroke-width="1.25"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M3.125 6.875H16.875"
          stroke="#626C70"
          stroke-width="1.25"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};

export const CubeIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
      >
        <path
          opacity="0.2"
          d="M4.13945 9.3269C4.04876 9.48073 4.00094 9.65605 4.00098 9.83463V22.1649C4.00098 22.3424 4.04819 22.5166 4.13777 22.6698C4.22735 22.8229 4.35607 22.9495 4.51072 23.0365L15.5107 29.224C15.6604 29.3082 15.8292 29.3525 16.001 29.3524L16.0023 29.3524L16.1196 15.9998L4.13948 9.32692L4.13945 9.3269Z"
          fill="#005CE8"
        />
        <path
          d="M28.001 22.165V9.83472C28.001 9.65728 27.9538 9.48304 27.8642 9.32987C27.7746 9.1767 27.6459 9.05013 27.4912 8.96314L16.4912 2.77564C16.3416 2.69145 16.1727 2.64722 16.001 2.64722C15.8292 2.64722 15.6604 2.69145 15.5107 2.77564L4.51072 8.96314C4.35607 9.05013 4.22735 9.1767 4.13777 9.32987C4.04819 9.48304 4.00098 9.65728 4.00098 9.83472V22.165C4.00098 22.3425 4.04819 22.5167 4.13777 22.6699C4.22735 22.823 4.35607 22.9496 4.51072 23.0366L15.5107 29.2241C15.6604 29.3083 15.8292 29.3525 16.001 29.3525C16.1727 29.3525 16.3416 29.3083 16.4912 29.2241L27.4912 23.0366C27.6459 22.9496 27.7746 22.823 27.8642 22.6699C27.9538 22.5167 28.001 22.3425 28.001 22.165Z"
          stroke="#005CE8"
          stroke-width="1.8"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M22.1258 19.0639V12.5639L9.99805 5.875"
          stroke="#005CE8"
          stroke-width="1.8"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M27.8613 9.32815L16.1178 15.9998L4.1377 9.3269"
          stroke="#005CE8"
          stroke-width="1.8"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M16.1173 16L16 29.3527"
          stroke="#005CE8"
          stroke-width="1.8"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};
