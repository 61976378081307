/** @format */

export const DATE_FORMATS = {
  'DD.MM.YYYY': 'DD.MM.YYYY',
  'DD-MM-YYYY': 'DD-MM-YYYY',
  'DD.MM.YYYY hh:MM': 'DD.MM.YYYY hh:MM',
  'DD MMM YY hh:MM': 'DD MMM YY hh:MM',
  'MMM DD YYYY hh:MM': 'MMM DD YYYY hh:MM',
  'YYYY-MM-DD': 'YYYY-MM-DD',
};

export const TICKET_SEVERITY = [
  {
    label: 'Minor',
    value: 'MINOR',
  },
  {
    label: 'Major',
    value: 'MAJOR',
  },
];

export const EXTENDED_TECH_SUPPORT_OPTIONS = [
  {
    label: 'Yes',
    value: true,
  },
  {
    label: 'No',
    value: false,
  },
];

export const TICKET_STAGES = {
  NO_STAGE: 'NO_STAGE',
  COMPLAINT_FORM_SUBMITTED: 'COMPLAINT_FORM_SUBMITTED',
  COMPLAINT_FORM_UPDATED: 'COMPLAINT_FORM_UPDATED',
  UNDER_WARRANTY: 'UNDER_WARRANTY',
  OUT_OF_WARRANTY: 'OUT_OF_WARRANTY',
  ASSIGN_SE: 'ASSIGN_SE',
  REASSIGN_SE: 'REASSIGN_SE',
  UPDATE_ACTION_CODE: 'UPDATE_ACTION_CODE',
  UPDATE_DEFECT_CODE: 'UPDATE_DEFECT_CODE',
  ENTER_ENGINE_DETAILS: 'ENTER_ENGINE_DETAILS',
  ENGINE_REPLACEMENT: 'ENGINE_REPLACEMENT',
  PARTS_REPLACEMENT: 'PARTS_REPLACEMENT',
  ENGINE_REPLACEMENT_REQ_SUBMITTED: 'ENGINE_REPLACEMENT_REQ_SUBMITTED',
  PARTS_REPLACEMENT_REQ_SUBMITTED: 'PARTS_REPLACEMENT_REQ_SUBMITTED',
  ACTION_CODE_UPDATED: 'ACTION_CODE_UPDATED',
  COMMENT: 'COMMENT',
  TICKET_UPDATED: 'TICKET_UPDATED',
  ATTACHMENT: 'ATTACHMENT',
};

export const USER_ROLES = {
  ADMIN:'ADMIN',
  OEM:'OEM'
}
