import React from "react";
import overview1 from "../../resources/images/overview1.png";
import overview2 from "../../resources/images/overview2.png";
import { Grid } from "@mui/material";

const Overview2 = () => {
  return (
    <div>
      <Grid container md={12}>
        <Grid item md={12}>
          <img src={overview1} width="100%" />
        </Grid>
        <Grid item md={12}>
          <img src={overview2} width="100%" />
        </Grid>
      </Grid>
    </div>
  );
};

export default Overview2;
