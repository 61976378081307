import React, { useState, useEffect } from "react";
import Select from "react-select";
import EditIcon from "@mui/icons-material/Edit";
import "./index.scss";
import { useParams } from "react-router";
import { formatDate, convertToCamelCaseFromUnderScore } from "../../utils";
import { DATE_FORMATS } from "../../utils/constants";
import { IconButton, Button, Typography, TextField } from "@mui/material";
import CustomModal from "../../core/modal";
import { invokeApi, HTTP_METHODS } from "../../utils/http-service";
import { HOSTNAME, REST_URLS } from "../../utils/endpoints";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { toast } from "react-toastify";

const BasicInfo = ({ ticketDetails, serviceEngineers = [], loadData }) => {
  const { severity } = ticketDetails;
  const { id = "" } = useParams();
  const [engineer, setEngineer] = useState({});
  const [reassign, setReassign] = useState(false);

  const [tempDate, setTempDate] = useState("");

  const updateDateOfInspection = () => {
    let payload = {
      dateOfInspection: new Date(tempDate).toISOString(),
    };
    invokeApi(
      HTTP_METHODS.PUT,
      `${HOSTNAME}${REST_URLS.TICKETS}/${id}`,
      payload
    ).then((response) => {
      setTempDate("");
      loadData();
    });
  };

  const updateSeverityInspection = (value) => {
    invokeApi(HTTP_METHODS.PUT, `${HOSTNAME}${REST_URLS.TICKETS}/${id}`, {
      severity: value,
    }).then((response) => {
      if (response?.message) {
        toast.error("Update Failed");
      } else {
        loadData();
      }
    });
  };

  useEffect(() => {
    serviceEngineers &&
      setEngineer(
        serviceEngineers.find((s) => s.id === ticketDetails.serviceEngineerId)
      );
  }, [serviceEngineers]);

  const assignEngineer = () => {
    if (engineer.id === ticketDetails.serviceEngineerId) {
      setReassign(false);
      setEngineer({});
      return;
    }

    invokeApi(HTTP_METHODS.PUT, `${HOSTNAME}${REST_URLS.ASSIGN_SE}/${id}`, {
      serviceEngineerId: engineer.id,
    }).then((response) => {
      setEngineer({});
      setReassign(false);
      loadData();
    });
  };

  return (
    <div className="basic-info-container-v2">
      <div className="first-section">
        <Typography className="ticket-id">Ticket id: {id}</Typography>
        <Typography className="ticket-id-info">
          Here is all your analytics overview
        </Typography>
      </div>
      <div className="middle-section">
        <div className="inspection-date">
          <span>Date of inspection</span>
          {tempDate ? (
            <div className="inspection-date-update">
              <TextField
                type="date"
                size="small"
                value={tempDate}
                onChange={(event) => {
                  setTempDate(event.target.value);
                }}
              />
              <IconButton onClick={() => updateDateOfInspection()}>
                <CheckCircleOutlineIcon color="primary" />
              </IconButton>
              <IconButton color="primary" onClick={() => setTempDate("")}>
                <HighlightOffIcon />
              </IconButton>
            </div>
          ) : (
            <TextField
              type="date"
              size="small"
              value={formatDate(
                ticketDetails.dateOfInspection,
                DATE_FORMATS["YYYY-MM-DD"]
              )}
              onChange={(event) => {
                setTempDate(event.target.value);
              }}
            />
          )}
        </div>
      </div>
      <div className="last-section">
        <Typography>Ticket Severity</Typography>
        <Button
          variant={severity === "MINOR" ? "contained" : "outlined"}
          onClick={() => updateSeverityInspection("MINOR")}
        >
          Minor
        </Button>
        <Button
          variant={severity === "MAJOR" ? "contained" : "outlined"}
          onClick={() => updateSeverityInspection("MAJOR")}
        >
          Major
        </Button>
      </div>
    </div>
  );
};

export default BasicInfo;
