import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./index.scss";
import AppLogo from "../../core/app-logo";
import FooterLinks from "../../core/footer-links";
import { ArrowForward } from "@mui/icons-material";
import { USER_ROLES } from "../../utils/constants";
import { getOfflineData } from "../../utils/offline-services";

const Home = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (getOfflineData("user")) {
      redirection(getOfflineData("user"));
    }
  }, []);

  const redirection = (user) => {
    if (user.role === USER_ROLES.OEM) {
      navigate("/oem");
    } else {
      navigate("/admin/dashboard");
    }
  };

  return (
    <div className="home-container">
      <div className="overlay"></div>
      <AppLogo />
      <FooterLinks />
      <div className="oem-login-direction">
        <span
          className="nav-link"
          onClick={() => navigate(`/login?type=${USER_ROLES.OEM}`)}
        >
          <span className="nav-text">OEM Login</span>
          <ArrowForward sx={{ color: "#fff" }} />
        </span>
      </div>
      <div className="admin-login-direction">
        <span
          className="nav-link"
          onClick={() => navigate(`/login?type=${USER_ROLES.ADMIN}`)}
        >
          <span className="nav-text">Admin Login</span>
          <ArrowForward sx={{ color: "#fff" }} />
        </span>
      </div>
    </div>
  );
};

export default Home;
