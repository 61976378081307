import { convertToCamelCaseFromUnderScore } from "../../utils";

export const HeaderConfig = [
  {
    label: "Ticket ID",
    key: "id",
    onClick: true,
    render: (data, onClick) => {
      return (
        <span className="clickable" onClick={() => onClick(data)}>
          {data.id}
        </span>
      );
    },
  },
  {
    label: "Created On",
    key: "createdAt",
    render: (data) => {
      return (
        <span>
          {data.createdAt && new Date(data.createdAt).toLocaleDateString()}{" "}
          {data.createdAt && new Date(data.createdAt).toLocaleTimeString()}
        </span>
      );
    },
  },
  {
    label: "Service Engineer",
    key: "serviceEngineer",
    render: (data) => {
      return <span>{data.serviceEngineer || "Not assigned"}</span>;
    },
  },
  {
    label: "OEM",
    key: "oem",
  },
  {
    label: "Status",
    key: "status",
    render: (data) => {
      const value = getStatus(data);
      return (
        <span
          className={
            value === "Opened"
              ? "red-col"
              : value === "Closed"
              ? "green-col"
              : "blue-col"
          }
        >
          {value}
        </span>
      );
    },
  },
];

export const SERVICE_LABEL_MAP = {
  firstService: "1st Service",
  secondService: "2nd Service",
  thirdService: "3rd Service",
  fourthService: "4th Service",
};

export const getStatus = (data) => {
  const { underWarranty, status, serviceEngineer, dateOfInspection } = data;
  if (
    status === "OPENED" &&
    underWarranty &&
    serviceEngineer &&
    dateOfInspection
  ) {
    return "JI Date fixed";
  } else if (status === "OPENED" && underWarranty && serviceEngineer) {
    return "Engineer Assigned";
  } else if (status === "OPENED" && underWarranty) {
    return "Acknowledged";
  } else if (status === "OPENED") {
    return "Open";
  } else {
    return convertToCamelCaseFromUnderScore(status);
  }
};
