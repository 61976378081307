import React, { useState, useEffect } from "react";
import Select from "react-select";
import EditIcon from "@mui/icons-material/Edit";
import "./index.scss";
import { useParams } from "react-router";
import { formatDate, convertToCamelCaseFromUnderScore } from "../../utils";
import { DATE_FORMATS } from "../../utils/constants";
import {
  IconButton,
  Button,
  Typography,
  TextField,
  InputAdornment,
} from "@mui/material";
import CustomModal from "../../core/modal";
import { invokeApi, HTTP_METHODS } from "../../utils/http-service";
import { HOSTNAME, REST_URLS } from "../../utils/endpoints";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { toast } from "react-toastify";
import { Edit } from "@mui/icons-material";

const BasicInfo2 = ({ ticketDetails, serviceEngineers = [], loadData }) => {
  const { severity, stage = "" } = ticketDetails;
  const { id = "" } = useParams();
  const [engineer, setEngineer] = useState({});
  const [reassign, setReassign] = useState(false);

  const [tempDate, setTempDate] = useState("");

  const updateDateOfInspection = () => {
    let payload = {
      dateOfInspection: new Date(tempDate).toISOString(),
    };
    invokeApi(
      HTTP_METHODS.PUT,
      `${HOSTNAME}${REST_URLS.TICKETS}/${id}`,
      payload
    ).then((response) => {
      setTempDate("");
      loadData();
    });
  };

  const updateSeverityInspection = (value) => {
    invokeApi(HTTP_METHODS.PUT, `${HOSTNAME}${REST_URLS.TICKETS}/${id}`, {
      severity: value,
    }).then((response) => {
      if (response?.message) {
        toast.error("Update Failed");
      } else {
        loadData();
      }
    });
  };

  useEffect(() => {
    serviceEngineers &&
      setEngineer(
        serviceEngineers.find((s) => s.id === ticketDetails.serviceEngineerId)
      );
  }, [serviceEngineers]);

  const assignEngineer = () => {
    if (engineer.id === ticketDetails.serviceEngineerId) {
      setReassign(false);
      setEngineer({});
      return;
    }

    invokeApi(HTTP_METHODS.PUT, `${HOSTNAME}${REST_URLS.ASSIGN_SE}/${id}`, {
      serviceEngineerId: engineer.id,
    }).then((response) => {
      setEngineer({});
      setReassign(false);
      loadData();
    });
  };

  return (
    <div className="basic-info-container2-v2">
      <div className="info-box">
        <div>Ticket Stage</div>
        <TextField
          size="small"
          disabled={true}
          fullWidth
          value={convertToCamelCaseFromUnderScore(stage)}
        />
      </div>
      <div className="info-box">
        <div>Engine Number</div>
        <TextField
          disabled={true}
          size="small"
          fullWidth
          value={ticketDetails.engineNo}
        />
      </div>
      <div className="info-box">
        <div>Created at</div>
        <TextField
          size="small"
          fullWidth
          disabled={true}
          value={formatDate(
            ticketDetails.createdAt,
            DATE_FORMATS["DD-MM-YYYY"]
          )}
        />
      </div>
      <div className="info-box">
        <div>Assigned Engineer</div>
        <TextField
          size="small"
          fullWidth
          disabled={true}
          value={ticketDetails.serviceEngineer}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => {
                    setReassign(true);
                    setEngineer(
                      serviceEngineers.find(
                        (s) => s.id === ticketDetails.serviceEngineerId
                      )
                    );
                  }}
                >
                  <Edit color="primary" />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </div>

      {reassign && (
        <CustomModal
          title="Reassign engineer"
          onClose={() => setReassign(false)}
        >
          <div className="reassign-container">
            <div>Service engineer</div>
            <Select
              placeholder="Select engineer"
              value={engineer || ""}
              classNamePrefix="si-select"
              options={serviceEngineers}
              getOptionLabel={(op) => op.name}
              getOptionValue={(op) => op.id}
              onChange={(value) => setEngineer(value)}
            />
            <Button
              fullWidth
              variant="contained"
              color="primary"
              size="small"
              disabled={!(engineer && engineer.id)}
              onClick={assignEngineer}
            >
              Update details
            </Button>
          </div>
        </CustomModal>
      )}
    </div>
  );
};

export default BasicInfo2;
