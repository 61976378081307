/** @format */

import React, { useState } from "react";
import { useParams } from "react-router-dom";

import Timeline from "@mui/lab/Timeline";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";

import "./index.scss";
import {
  formatDate,
  convertToCamelCaseFromUnderScore,
  splitFileName,
} from "../../utils";
import { DATE_FORMATS, TICKET_STAGES } from "../../utils/constants";
import { convertPartsToString } from "./helper";
import { Button, Stack, TextField, Typography } from "@mui/material";
import { HTTP_METHODS, invokeApi } from "../../utils/http-service";
import { HOSTNAME, REST_URLS } from "../../utils/endpoints";
import { toast } from "react-toastify";
import CustomModal from "../../core/modal";
import { Check } from "@mui/icons-material";

const TimeLineInfo = ({ ticketDetails, openForm, loadData, timeline }) => {
  const { id } = useParams();
  const [openModal, setOpenModal] = useState(null);
  const [message, setMessage] = useState("");

  const updateDateOfInspection = (payload) => {
    invokeApi(
      HTTP_METHODS.PUT,
      `${HOSTNAME}${REST_URLS.TICKETS}/${id}`,
      payload
    ).then((response) => {
      if (response?.message) {
        toast.error("Update Failed");
      } else {
        loadData();
      }
    });
  };

  const getTimeLine = (timelineDetails, index) => {
    const { stage, stageDetails } = timelineDetails;

    let stageDetailsObj = {};
    try {
      stageDetailsObj = JSON.parse(stageDetails);
    } catch (err) {}

    switch (stage) {
      case TICKET_STAGES.COMPLAINT_FORM_SUBMITTED:
        return (
          <div key={index}>
            <Typography className="line1">
              Created on{" "}
              {formatDate(
                stageDetailsObj.complaintRecordCreatedAt,
                DATE_FORMATS["DD-MM-YYYY"]
              )}{" "}
              by{" "}
              {stageDetailsObj.complaintRecordId &&
                stageDetailsObj.complaintRecordId.oemName}
            </Typography>
            <Typography className="line2 clickable" onClick={openForm}>
              View form
            </Typography>
          </div>
        );
      case TICKET_STAGES.ASSIGN_SE:
        return (
          <div key={index}>
            <Typography className="line1">Service Engineer Assigned</Typography>
            <Typography className="line2">
              {stageDetailsObj.name || "-"}
            </Typography>
          </div>
        );
      case TICKET_STAGES.UPDATE_DEFECT_CODE:
        return (
          <div key={index}>
            <Typography className="line1">Defect code updated</Typography>
            <div className="line2">
              {(stageDetailsObj.defectCode &&
                `${stageDetailsObj.defectCode.code} - ${stageDetailsObj.defectCode.description}`) ||
                "-"}
            </div>
          </div>
        );
      case TICKET_STAGES.UPDATE_ACTION_CODE:
        return (
          <div key={index}>
            <Typography className="line1">Action code updated</Typography>
            <div className="line2">
              {(stageDetailsObj.actionCode &&
                `${stageDetailsObj.actionCode.code} - ${stageDetailsObj.actionCode.description}`) ||
                "-"}
            </div>
          </div>
        );
      case TICKET_STAGES.ENGINE_REPLACEMENT:
        return (
          <div key={index} className="time-c-extra">
            <Typography className="line1">
              Engine replacement details
            </Typography>
            <div className="line2">
              <Typography>
                {" "}
                {`Replacement By: ${stageDetailsObj.replacementBy}`}
              </Typography>
              <Typography>
                {`Engine Type: ${convertToCamelCaseFromUnderScore(
                  stageDetailsObj.engineType
                )}`}
              </Typography>
              <Typography>
                {`Engine Type Value: ${stageDetailsObj.engineTypeValue}`}
              </Typography>
              <Typography>
                {" "}
                {`Extra Text: ${stageDetailsObj.extraText}`}
              </Typography>
            </div>
          </div>
        );
      case TICKET_STAGES.PARTS_REPLACEMENT:
        return (
          <div key={index} className="time-c-extra">
            <Typography className="line1">
              Part/s replacement details
            </Typography>
            <div className="line2">
              <Typography>
                {" "}
                {`Mode :${convertToCamelCaseFromUnderScore(
                  stageDetailsObj.mode
                )}`}
              </Typography>
              <Typography> {`Scope:${stageDetailsObj.scope}`}</Typography>
              <Typography>
                {`Parts :${convertPartsToString(
                  stageDetailsObj.partsReplacementIds || []
                )}`}
              </Typography>
              <Typography> {`Extra :${stageDetailsObj.extra}`}</Typography>
            </div>
          </div>
        );
      case TICKET_STAGES.COMMENT:
        return (
          <div key={index}>
            <Typography className="line1">Comment</Typography>
            <Typography className="line2">
              {stageDetailsObj.comment || "-"}
            </Typography>
          </div>
        );
      case TICKET_STAGES.TICKET_UPDATED:
      case TICKET_STAGES.ENTER_ENGINE_DETAILS:
        const { reason = "", status = "" } = stageDetailsObj || {};
        if (reason.includes("Date of inspection")) {
          return (
            <div key={index}>
              <Typography className="line1">
                {timelineDetails.description}
              </Typography>
              <Typography className="line2">
                Date of inspection confirmed on{" "}
                {formatDate(
                  ticketDetails.dateOfInspection,
                  DATE_FORMATS["DD-MM-YYYY"]
                )}
              </Typography>
            </div>
          );
        }
        return (
          <div key={index}>
            <Typography className="line1">
              {timelineDetails.description || "Ticket Updated"}
            </Typography>
            <Typography className="line2">
              {stageDetailsObj.reason ? stageDetailsObj.reason : status || "-"}
            </Typography>
          </div>
        );
      case TICKET_STAGES.OUT_OF_WARRANTY:
        return (
          <div key={index}>
            <Typography className="line1">
              {timelineDetails.description || "Ticket Updated"}
            </Typography>
            <Typography className="line2">
              {stageDetailsObj.extendedTechnicalSupport ? "Yes" : "No"}
            </Typography>
          </div>
        );
      case TICKET_STAGES.ATTACHMENT:
        return (
          <div key={index}>
            <Typography className="line1">Attachment</Typography>
            <Typography className="line2">
              <a
                className="open-link"
                href={splitFileName(stageDetailsObj.url)}
                target="_blank"
              >
                Open attachment
              </a>
            </Typography>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="time-line-info-container">
      <div>
        <div className="tl-header">
          <Typography>Progress Tracker</Typography>
        </div>
      </div>
      <div className="time-line">
        <Timeline
          sx={{
            [`& .${timelineItemClasses.root}:before`]: {
              flex: 0,
              padding: 0,
            },
          }}
        >
          {timeline.map((timelineDetails, index) => (
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot
                  style={{
                    backgroundColor: "#102f77",
                  }}
                >
                  <Check fontSize="12" />
                </TimelineDot>
                <TimelineConnector
                  style={{
                    backgroundColor: "#306FFF",
                  }}
                />
              </TimelineSeparator>
              <TimelineContent>
                {getTimeLine(timelineDetails, index)}
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
      </div>

      <div className="td-buttons-container">
        {(ticketDetails.status === "RESOLVED" ||
          ticketDetails.status === "CLOSED") && (
          <Button
            variant="contained"
            color="primary"
            sx={{ borderRadius: 160 }}
            onClick={() =>
              updateDateOfInspection({
                status: "REOPENED",
              })
            }
          >
            Reopen ticket
          </Button>
        )}
        {(ticketDetails.status === "OPENED" ||
          ticketDetails.status === "REOPENED") && (
          <Button
            variant="contained"
            color="primary"
            sx={{ borderRadius: 160 }}
            onClick={() => setOpenModal(true)}
          >
            Resolve Ticket
          </Button>
        )}

        <Button
          variant="outlined"
          color="primary"
          sx={{ borderRadius: 160 }}
          disabled={
            ticketDetails.status === "RESOLVED" ||
            ticketDetails.status === "CLOSED"
          }
          onClick={() => updateDateOfInspection({ status: "CLOSED" })}
        >
          Close ticket
        </Button>
      </div>

      {openModal && (
        <CustomModal
          title="Resolve ticket"
          onClose={() => {
            setOpenModal(false);
            setMessage("");
          }}
        >
          <div className="resolve-msg">
            <TextField
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              fullWidth
              size="small"
              multiline
              rows={4}
              placeholder="Resolve message"
            />
            <Stack
              direction="row"
              spacing={2}
              sx={{ m: 1, justifyContent: "space-between" }}
            >
              <Button
                variant="contained"
                color="inherit"
                sx={{ mt: 1, borderRadius: 160 }}
                onClick={() => {
                  setOpenModal(false);
                  setMessage("");
                }}
              >
                Cancel
              </Button>

              <Button
                variant="contained"
                color="primary"
                sx={{ mt: 1, borderRadius: 160 }}
                onClick={() => {
                  setMessage("");
                  setOpenModal(false);
                  updateDateOfInspection({
                    status: "RESOLVED",
                    resolveMessage: message,
                  });
                }}
              >
                {ticketDetails.status === "RESOLVED" ||
                ticketDetails.status === "CLOSED"
                  ? "Reopen ticket"
                  : "Resolve ticket"}
              </Button>
            </Stack>
          </div>
        </CustomModal>
      )}
    </div>
  );
};

export default TimeLineInfo;
