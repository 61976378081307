import React, { useEffect, useState } from "react";
import { Pagination, Typography } from "@mui/material";
import "./index.scss";
import Select from "react-select";

const SiTable = ({
  header,
  data,
  pageCount,
  onClick,
  onChange,
  title,
  currentPage,
  displayPageDropDown,
}) => {
  const [selectPage, setSelectedPage] = useState({});
  const options = [];

  useEffect(() => {
    if (selectPage?.value !== currentPage) {
      setSelectedPage({
        label: currentPage,
        value: currentPage,
      });
    }
  }, [currentPage]);

  for (let i = 1; i <= pageCount; i++) {
    options.push({ label: i, value: i });
  }  
  return (
    <div className="si-table-container">
      {title && (
        <div className="title-bar">
          <Typography>{title}</Typography>
        </div>
      )}
      <div className="si-header">
        <div className="si-row">
          {header.map((h) => {
            return (
              <Typography className="si-cell tr-text" key={h.label}>
                {h.label}
              </Typography>
            );
          })}
        </div>
      </div>
      <div className="si-body">
        {data.map((rowData) => {
          return (
            <div className="si-row">
              {header.map((h, i) => {
                if (h.render) {
                  return (
                    <Typography className="si-cell">
                      {h.render(rowData, onClick)}
                    </Typography>
                  );
                }
                return (
                  <div>
                    <Typography className="si-cell si-hide-mobile" key={i}>
                      {rowData[h.key]}
                    </Typography>
                    <Typography className="si-cell-mobile si-hide-web" key={i}>
                      {rowData[h.key]}
                    </Typography>
                  </div>
                );
              })}
            </div>
          );
        })}
        {data.length === 0 && <span className="no-records">No records</span>}
      </div>
      <div className="si-pagination">
        {displayPageDropDown && (
          <Select
            menuPlacement="top"
            className="si-pagination-select"
            options={options}
            value={selectPage}
            onChange={(value) => {
              setSelectedPage(value);
              onChange({}, value.value);
            }}
          />
        )}
        {pageCount > 1 && (
          <Pagination
            count={pageCount}
            color="primary"
            onChange={(e, page) => {
              setSelectedPage({ value: page, label: page });
              onChange(e, page);
            }}
            page={selectPage?.value || 1}
            showFirstButton
            showLastButton
          ></Pagination>
        )}
      </div>
    </div>
  );
};

export default SiTable;
