import React, { useContext, useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import { IconButton, Drawer, Button } from "@mui/material";
import "./index.scss";
import AppSideMenu from "../side-menu";
import AppContext from "../../utils/context";
import { USER_ROLES } from "../../utils/constants";
import { Logout } from "@mui/icons-material";
import { clearOfflineData } from "../../utils/offline-services";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();
  const { userDetails = {} } = useContext(AppContext);
  const { role = "" } = userDetails;
  const [openMenu, setOpenMenu] = useState(false);

  const logout = () => {
    clearOfflineData("user");
    navigate("/");
  };

  if (role === USER_ROLES.OEM) {
    return (
      <div className="header-container">
        <div className="header-logo-container">
          <img
            src={require("../../resources/icons/logo.png")}
            className="app-logo"
          />
        </div>
        <div>
          <Button
            variant="text"
            endIcon={<Logout />}
            size="small"
            onClick={logout}
          >
            Logout
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className="header-container">
      <div className="hc-right">
        <IconButton onClick={() => setOpenMenu(true)}>
          <MenuIcon />
        </IconButton>
        <div className="header-logo-container">
          <img
            src={require("../../resources/icons/logo.png")}
            className="app-logo"
          />
        </div>
      </div>
      <span></span>
      <div>
        <Button
          variant="text"
          endIcon={<Logout />}          
          size="small"
          onClick={logout}
        >
          Logout
        </Button>
      </div>

      <Drawer open={openMenu} onClose={() => setOpenMenu(false)}>
        <AppSideMenu />
      </Drawer>
    </div>
  );
};

export default Header;
