/** @format */

import "./index.scss";
import Header from "../core/header";
import { useContext } from "react";
import AppContext from "../utils/context";
import { USER_ROLES } from "../utils/constants";
import AppSideMenu from "../core/side-menu";
const MainContainer = ({ children }) => {
  const { userDetails = {} } = useContext(AppContext);

  if (userDetails.role === USER_ROLES.OEM) {
    return (
      <div className="oem-main-container">
        <Header />
        <div className="main-right-container">{children}</div>
      </div>
    );
  }

  return (
    <div className="main-container">
      <div className="main-left-container si-hide-mobile">
        <AppSideMenu />
      </div>
      <div className="main-right-container">        
        <Header />
        {children}
      </div>
    </div>
  );
};

export default MainContainer;
