import * as React from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import "./index.scss";
import {
  ComplaintHandlingIcon,
  DashboardIcon1,
  TimeIcon,
} from "../../resources/icons";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { clearOfflineData } from "../../utils/offline-services";
import AppContext from "../../utils/context";
import { getActiveSideMenu, getActiveSubMenu } from "./helper";
import { DashboardOutlined } from "@mui/icons-material";

const ACTIVE_MENU = {
  color: "#005CE8",
};

const ACTIVE_SIDE_NAV_STYLES = {
  borderLeft: "4px solid #fff",
  "&.Mui-selected": {
    borderLeft: "4px solid #306FFF",
  },
};

export default function AppSideMenu() {
  const { activeRoute, setActiveRoute } = React.useContext(AppContext);
  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();
  const [subMenu, setSubMenu] = React.useState("");
  const { pathname } = useLocation();

  React.useEffect(() => {
    if (!activeRoute) {
      const activePath = getActiveSideMenu(pathname);
      setActiveRoute(activePath);
      let queryIndex = searchParams.get("index");
      if (
        activePath === "Complaint Overview" ||
        activePath === "Complaint Handling"
      ) {
        setSubMenu(getActiveSubMenu(queryIndex, pathname));
      }
    }
  }, [pathname]);

  const handleClick = (tab) => {
    setActiveRoute(tab);
    setSubMenu("");
  };
  const logout = () => {
    clearOfflineData("user");
    navigate("/");
  };

  const subMenuClick = (subTab, route) => {
    navigate(route);
    setSubMenu(subTab);
  };

  return (
    <div className="side-nav-container">
      <div className="side-app-logo-container">
        <img
          src={require("../../resources/icons/logo.png")}
          className="app-logo"
        />
      </div>
      <List
        sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
        component="nav"
      >
        <ListItemButton
          sx={ACTIVE_SIDE_NAV_STYLES}
          selected={activeRoute === "Dashboard"}
          onClick={() => {
            handleClick("Dashboard");
            navigate("/admin/dashboard");
          }}
        >
          <ListItemIcon sx={{ minWidth: 35 }}>
            <DashboardIcon1 active={activeRoute === "Dashboard"} />
          </ListItemIcon>
          <ListItemText primary="Overview" />
        </ListItemButton>
        <ListItemButton
          sx={ACTIVE_SIDE_NAV_STYLES}
          selected={activeRoute === "Overview"}
          onClick={() => handleClick("Overview")}
        >
          <ListItemIcon sx={{ minWidth: 35 }}>
            <DashboardOutlined
              a
              color={activeRoute === "Overview" ? "primary" : ""}
            />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
          {activeRoute === "Overview" ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={activeRoute === "Overview"} timeout="auto" unmountOnExit>
          {/* <List component="div" disablePadding>
            <ListItemButton
              sx={{ pl: "58px" }}
              onClick={() =>
                subMenuClick("Engine Replacement", "/admin/overview")
              }
            >
              <ListItemText
                sx={subMenu === "Engine Replacement" ? ACTIVE_MENU : {}}
                primary="Engine Replacement"
              />
            </ListItemButton>
          </List> */}
          <List component="div" disablePadding>
            <ListItemButton
              sx={{ pl: "58px" }}
              onClick={() =>
                subMenuClick("Trend Overview", "/admin/overview?index=8")
              }
            >
              <ListItemText
                sx={subMenu === "Trend Overview" ? ACTIVE_MENU : {}}
                primary="Trend Overview"
              />
            </ListItemButton>
          </List>
        </Collapse>

        <ListItemButton
          sx={ACTIVE_SIDE_NAV_STYLES}
          selected={activeRoute === "Complaint Overview"}
          onClick={() => handleClick("Complaint Overview")}
        >
          <ListItemIcon sx={{ minWidth: 35 }}>
            <TimeIcon active={activeRoute === "Complaint Overview"} />
          </ListItemIcon>
          <ListItemText primary="Complaint Overview" />
          {activeRoute === "Complaint Overview" ? (
            <ExpandLess />
          ) : (
            <ExpandMore />
          )}
        </ListItemButton>
        <Collapse
          in={activeRoute === "Complaint Overview"}
          timeout="auto"
          unmountOnExit
        >
          <List component="div" disablePadding>
            <ListItemButton
              sx={{ pl: "58px" }}
              onClick={() =>
                subMenuClick("OEM Wise", "/admin/overview?index=2")
              }
            >
              <ListItemText
                sx={subMenu === "OEM Wise" ? ACTIVE_MENU : {}}
                primary="OEM Wise"
              />
            </ListItemButton>
          </List>

          <List component="div" disablePadding>
            <ListItemButton
              sx={{ pl: "58px" }}
              onClick={() =>
                subMenuClick("Region Wise", "/admin/overview?index=3")
              }
            >
              <ListItemText
                sx={subMenu === "Region Wise" ? ACTIVE_MENU : {}}
                primary="Region Wise"
              />
            </ListItemButton>
          </List>

          <List component="div" disablePadding>
            <ListItemButton
              sx={{ pl: "58px" }}
              onClick={() =>
                subMenuClick("Engine Model Wise", "/admin/overview?index=4")
              }
            >
              <ListItemText
                sx={subMenu === "Engine Model Wise" ? ACTIVE_MENU : {}}
                primary="Engine Model Wise"
              />
            </ListItemButton>
          </List>
          <List component="div" disablePadding>
            <ListItemButton
              sx={{ pl: "58px" }}
              onClick={() =>
                subMenuClick("Hour Wise", "/admin/overview?index=5")
              }
            >
              <ListItemText
                sx={subMenu === "Hour Wise" ? ACTIVE_MENU : {}}
                primary="Hour Wise"
              />
            </ListItemButton>
          </List>
          <List component="div" disablePadding>
            <ListItemButton
              sx={{ pl: "58px" }}
              onClick={() =>
                subMenuClick("Part Wise Trend", "/admin/overview?index=7")
              }
            >
              <ListItemText
                sx={subMenu === "Part Wise Trend" ? ACTIVE_MENU : {}}
                primary="Part Wise Trend"
              />
            </ListItemButton>
          </List>
        </Collapse>

        <ListItemButton
          sx={ACTIVE_SIDE_NAV_STYLES}
          selected={activeRoute === "Complaint Handling"}
          onClick={() => handleClick("Complaint Handling")}
        >
          <ListItemIcon sx={{ minWidth: 35 }}>
            <ComplaintHandlingIcon
              active={activeRoute === "Complaint Handling"}
            />
          </ListItemIcon>
          <ListItemText primary="Complaint Handling" />
          {activeRoute === "Complaint Handling" ? (
            <ExpandLess />
          ) : (
            <ExpandMore />
          )}
        </ListItemButton>
        <Collapse
          in={activeRoute === "Complaint Handling"}
          timeout="auto"
          unmountOnExit
        >
          <List component="div" disablePadding>
            <ListItemButton
              sx={{ pl: "58px" }}
              onClick={() => subMenuClick("Tickets", "/admin/tickets")}
            >
              <ListItemText
                primary="Tickets"
                sx={subMenu === "Tickets" ? ACTIVE_MENU : {}}
              />
            </ListItemButton>
            <ListItemButton
              sx={{ pl: "58px" }}
              onClick={() => subMenuClick("Reports", "/admin/reports")}
            >
              <ListItemText
                primary="Reports"
                sx={subMenu === "Reports" ? ACTIVE_MENU : {}}
              />
            </ListItemButton>
            <ListItemButton
              sx={{ pl: "58px" }}
              onClick={() => subMenuClick("Admin Settings", "/admin/setup")}
            >
              <ListItemText
                primary="Admin Settings"
                sx={subMenu === "Admin Settings" ? ACTIVE_MENU : {}}
              />
            </ListItemButton>
          </List>
        </Collapse>
      </List>
      {/* <div className="logout-container">
        <span className="logout-text" onClick={logout}>
          Log Out
        </span>
      </div> */}
    </div>
  );
}
