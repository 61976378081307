import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import "./index.scss";
import { invokeApi, HTTP_METHODS } from "../../utils/http-service";
import { HOSTNAME, REST_URLS } from "../../utils/endpoints";
import {
  setOfflineData,
  getOfflineData,
  clearOfflineData,
} from "../../utils/offline-services";
import { toast } from "react-toastify";
import AppContext from "../../utils/context";
import AppLogo from "../../core/app-logo";
import FooterLinks from "../../core/footer-links";
import { USER_ROLES } from "../../utils/constants";
import LoginCard from "../../core/login-card";

const Login = () => {
  const { setUserDetails, setActiveRoute } = useContext(AppContext);
  const [rememberMe, setRememberMe] = useState(false);
  const navigate = useNavigate();
  const [loginDetails, setLoginDetails] = useState({});
  const [searchParams] = useSearchParams();
  let userType = searchParams.get("type") || USER_ROLES.ADMIN;

  useEffect(() => {
    if (getOfflineData("user")) {
      redirection(getOfflineData("user"));
    }
    const saveDetails = getOfflineData(`${userType}-loginDetails`);
    if (saveDetails) {
      setLoginDetails(saveDetails);
      setRememberMe(true);
    }
  }, []);

  const redirection = (user) => {
    if (userType === USER_ROLES.OEM) {
      navigate("/oem");
    } else {
      navigate("/admin/dashboard");
      setActiveRoute("Dashboard");
    }
  };

  const onInputChange = (event) => {
    setLoginDetails({
      ...loginDetails,
      [event.target.name]: event.target.value,
    });
  };

  const login = () => {
    // Handle login logic here
    if (rememberMe) {
      setOfflineData(`${userType}-loginDetails`, loginDetails);
    } else {
      clearOfflineData(`${userType}-loginDetails`);
    }

    let payload = {
      email: loginDetails.email,
      password: loginDetails.password,
    };

    let url = REST_URLS.LOGIN;

    if (userType === USER_ROLES.OEM) {
      url = REST_URLS.OEM_LOGIN;
    }

    invokeApi(HTTP_METHODS.POST, `${HOSTNAME}${url}`, payload).then(
      (response) => {
        const { user, oem, tokens = {} } = response;
        const userData = user || oem;
        if (userType === USER_ROLES.OEM) {
          userData.role = USER_ROLES.OEM;
        }

        if (response.message) {
          toast.error(response.message);
          setLoginDetails({
            ...loginDetails,
            errorMsg: response.message,
          });
        } else {
          setUserDetails(userData);
          userData && setOfflineData("user", userData);
          tokens && setOfflineData("tokens", tokens);
          redirection(userData);
        }
      }
    );
  };

  return (
    <div
      className={
        USER_ROLES.ADMIN === userType
          ? "login-container admin-login-image"
          : "login-container oem-login-image"
      }
    >
      <div className="overlay"></div>
      <AppLogo onClick={() => navigate("/")} />
      <FooterLinks userType={userType} />
      <div className="login-box">
        <LoginCard
          title={USER_ROLES.ADMIN === userType ? "Admin Login" : "OEM Login"}
          onLogin={login}
          onInputChange={onInputChange}
          setRememberMe={setRememberMe}
          loginDetails={loginDetails}
          rememberMe={rememberMe}
        />
      </div>
    </div>
  );
};

export default Login;
