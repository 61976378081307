import { convertCamelCaseToDisplayText } from "../../utils";

export const getActiveSideMenu = (pathname) => {
  if (pathname.includes("dashboard")) return "Dashboard";
  if (pathname.includes("overview")) return "Complaint Overview";

  if (
    pathname.includes("tickets") ||
    pathname.includes("reports") ||
    pathname.includes("setup")
  )
    return "Complaint Handling";

  return "";
};

export const getActiveSubMenu = (queryIndex, pathname) => {
  if (pathname.includes("reports")) {
    return convertCamelCaseToDisplayText("Reports");
  }

  if (pathname.includes("tickets")) {
    return convertCamelCaseToDisplayText("Tickets");
  }

  if (pathname.includes("setup")) {
    return convertCamelCaseToDisplayText("Setup");
  }

  switch (queryIndex) {
    case "1":
      return "OEM Wise";
    case "2":
      return "OEM Wise";
    case "3":
      return "Region Wise";
    case "4":
      return "Engine Model Wise";
    case "5":
      return "Hour Wise";
  }

  return "";
};
