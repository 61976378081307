import { Avatar, Button, Typography } from "@mui/material";
import "./index.scss";
import { CubeIcon } from "../../../resources/icons";
import { ArrowDownward, ArrowForward } from "@mui/icons-material";
import { useContext, useState } from "react";
import { ACTIVE_BUTTON_CONFIG } from "./helper";
import { useNavigate } from "react-router-dom";
import AppContext from "../../../utils/context";
const AdminDashboard = () => {
  const { userDetails } = useContext(AppContext);
  const navigate = useNavigate();
  const [activeButton, setActiveButton] = useState("Dashboard");

  const onImageError = (event) => {
    event.target.src = require("../../../resources/images/engine.png");
  };
  return (
    <div className="admin-dashboard-container">
      <div>
        <Typography className="user-name">
          👋 Welcome {userDetails?.name}{" "}
        </Typography>
        <Typography className="msg-info">
          Here is all your analytics overview{" "}
        </Typography>
      </div>
      <div className="main-card">
        <div>
          <Typography className="mc-info">
            It is a 175-year long journey through ceaseless changes, economic
            transitions and technological transformations. A tribute to the
            leadership, spirit of enterprise and wisdom of Shri. S.
            Anantharamakrishnan, the Founder of the Amalgamations Group, who
            envisioned the future of Simpsons.
          </Typography>
          <div className="mc-buttons">
            {Object.keys(ACTIVE_BUTTON_CONFIG).map((buttonText) => {
              return (
                <Button
                  endIcon={
                    buttonText === activeButton ? (
                      <ArrowDownward />
                    ) : (
                      <ArrowForward />
                    )
                  }
                  onClick={() => setActiveButton(buttonText)}
                  variant={
                    buttonText === activeButton ? "contained" : "outlined"
                  }
                  sx={{
                    borderRadius: 120,
                    width: "220px",
                  }}
                >
                  {buttonText}
                </Button>
              );
            })}
          </div>
        </div>
        <div className="mc-image-container">
          <img
            className="mc-image"
            onError={onImageError}
            src={require("../../../resources/images/engine.gif")}
          />
        </div>
      </div>
      <div className="dashboard-card-list">
        {ACTIVE_BUTTON_CONFIG[activeButton]?.map((card) => {
          return (
            <div className="dashboard-card">
              <Avatar sx={{ background: "#F0F6FF" }}>
                <CubeIcon />
              </Avatar>
              <Typography className="card-title">{card.label}</Typography>
              <Typography className="card-info">{card.info}</Typography>
              <Button
                endIcon={<ArrowForward />}
                onClick={() => navigate(card.redirectTo)}
                variant={"outlined"}
                sx={{
                  borderRadius: 120,
                  width: "220px",
                }}
              >
                {card.label}
              </Button>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default AdminDashboard;
