import React from "react";
import engineImage1 from "../../resources/images/Engine Replacement summary 1.png";
import engineImage2 from "../../resources/images/Engine Replacement summary 2.png";
import engineImage3 from "../../resources/images/Engine Replacement summary 3.png";
import engineImage4 from "../../resources/images/Engine Replacement summary 4.png";
import { Grid } from "@mui/material";

const EngineReplacement2 = () => {
  return (
    <div>
      <Grid container md={12}>
        <Grid item md={12} sx={{ mb: 2 }}>
          <img src={engineImage1} width="100%" />
        </Grid>
        <Grid item md={5.5}>
          <img src={engineImage2} width="100%" />
        </Grid>
        <Grid item md={4}>
          <img src={engineImage3} width="100%" />
        </Grid>
        <Grid item md={2.5}>
          <img src={engineImage4} width="100%" />
        </Grid>
      </Grid>
    </div>
  );
};

export default EngineReplacement2;
