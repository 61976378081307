export const Theme = {
  typography: {
    fontFamily: ["PublicSans-Regular"],
  },
  shape: {
    borderRadius: 8,
  },
  palette: {
    primary: {
      main: "#005CE8",
    },
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 160,
      },
      containedPrimary: {
        color: "#fff",
      },
      text: {
        color: "#306FFF",
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 30,
      },
    },
    MuiTypography: {
      colorTextSecondary: {
        color: "#fff",
      },
    },
  },
};
