import React from "react";
import DatamapsIndia from "react-datamaps-india";
import DownloadIcon from "@mui/icons-material/Download";
import { IconButton } from "@mui/material";
import "./index.scss";
import { exportToExcel } from "../../utils";

const IndiaMap = ({ data = {}, title, downloadingData }) => {
  return (
    <div className="india-map-card-container">
      <div className="title">
        {title}{" "}
        <IconButton
          disabled={downloadingData.length === 0}
          onClick={() => exportToExcel(downloadingData, "state")}
        >
          <DownloadIcon />
        </IconButton>
      </div>

      <div className="india-map-container">
        <DatamapsIndia
          regionData={data}
          hoverComponent={({ value }) => {
            debugger;
            return (
              <div>
                <div>
                  {value.name} : {value.value || 0}
                </div>
              </div>
            );
          }}
          mapLayout={{
            legendTitle: "Count",
            startColor: "#86b1f4",
            endColor: "#015CE8",
            hoverTitle: "Count",
            noDataColor: "#f5f5f5",
            borderColor: "#8D8D8D",
            hoverBorderColor: "#8D8D8D",
            hoverColor: "#005CE8",
            height: 30,
            weight: 20,
          }}
        />
      </div>
    </div>
  );
};
export default IndiaMap;
