import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "../components/login";
import PrivateRoute from "./private-route";
import NotFound from "../components/not-found";
import Setup from "../components/setup";
import Reports from "../components/reports";
import PastTickets from "../components/past-ticket";
import CreateTicket from "../components/create-ticket";
import Home from "../components/home";
import AdminDashboard from "../components/admin/dashboard";
import Overview from "../components/overview";
import TicketDetailsV2 from "../components/ticket-details-v2";

const AppRoutes = () => {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />}></Route>
        <Route exact path="/login" element={<Login />}></Route>
        <Route exact path="/admin" element={<PrivateRoute />}>
          <Route exact path="/admin/dashboard" element={<AdminDashboard />} />
          <Route exact path="/admin/overview" element={<Overview />} />
          <Route exact path="/admin/setup" element={<Setup />} />
          <Route exact path="/admin/reports" element={<Reports />} />
          <Route exact path="/admin/tickets" element={<PastTickets />} />
          <Route exact path="/admin/details/:id" element={<TicketDetailsV2 />} />
        </Route>
        <Route exact path="/oem" element={<PrivateRoute />}>
          <Route exact path="/oem" element={<PastTickets />} />
          <Route exact path="/oem/create" element={<CreateTicket />} />
        </Route>
        <Route path="*" element={<NotFound />}></Route>
      </Routes>
    </Router>
  );
};

export default AppRoutes;
