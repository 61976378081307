/** @format */

import { Avatar, IconButton } from "@mui/material";
import { Edit } from "@mui/icons-material";
export const getHeaderConfig = (loadData, onEdit) => [
  {
    label: "Name",
    key: "name",
  },
  {
    label: "Actions",
    key: "actions",
    render: (data) => {
      return (
        <IconButton onClick={() => onEdit(data)} id="delete" onCL sx={{ p: 0 }}>
          <Avatar sx={{ bgcolor: "#E7F7EF" }}>
            <Edit id="edit" sx={{ color: "#0FAF62" }} />
          </Avatar>
        </IconButton>
      );
    },
  },
];
