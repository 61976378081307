import React from "react";

import "./index.scss";
import { splitFileName, getFileNameFromUrl } from "../../utils";
import { TICKET_STAGES } from "../../utils/constants";
import { Typography, Divider } from "@mui/material";

import {
  InsertDriveFileOutlined,
  KeyboardArrowDown,
} from "@mui/icons-material";

const TicketFiles = ({ timeline = [] }) => {
  return (
    <div className="ticket-files-container">
      <div className="tf-header">
        <Typography>Files Shared</Typography>
        <KeyboardArrowDown />
      </div>
      <Divider />
      <div className="t-files">
        {timeline.map((line) => {
          if (line.stage === TICKET_STAGES.ATTACHMENT) {
            let stageDetailsObj = {};

            try {
              stageDetailsObj = JSON.parse(line.stageDetails);
            } catch (err) {}

            return (
              <div className="attachment-container">
                <InsertDriveFileOutlined />
                <a
                  className="file-name"
                  href={splitFileName(stageDetailsObj.url)}
                  target="_blank"
                >
                  {getFileNameFromUrl(stageDetailsObj.url)}
                </a>
              </div>
            );
          }
          return (line.attachments || []).map((attachment) => {
            return <Typography>{attachment.name}</Typography>;
          });
        })}
      </div>
    </div>
  );
};

export default TicketFiles;
