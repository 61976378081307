import React from "react";
import { Typography } from "@mui/material";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import "./index.scss";

ChartJS.register(ArcElement, Tooltip, Legend);

const options = {
  plugins: {
    legend: {
      position: "right", // Change the legend position here (e.g., 'top', 'left', 'right')
    },
  },
  responsive: true,
};


const CustomPiChart = ({ title, data = [], xDataKey, bDataKey }) => {
  const width = window.innerWidth > 420 ? 500 : 300;

  const state = {
    labels: data.map((d) => d[xDataKey]),
    datasets: [
      {
        label: "Count",
        data: data.map((d) => d[bDataKey]),
        backgroundColor: [
          "#065535",
          "#ffa500",
          "#ff7373",
          "#bada55",
          "#DC143C",
          "#8B008B",
          "#8B0000",
          "#483D8B",
          "rgba(153, 102, 255)",
          "#20B2AA",
        ]               
      },
    ],
  };

  return (
    <div className="pic-chart-container">
      <Typography className="title">{title}</Typography>
      {data.length > 0 ? (
        <div  className="pi-chart-doughnut">
          <Doughnut data={state} options={options} />;
        </div>
      ) : (
        <div
          style={{
            width: `${width}px`,
          }}
        >
          No data to display
        </div>
      )}
    </div>
  );
};

export default CustomPiChart;
